<template>
	<div class="firstForm">
		<Header></Header>
		<div class="wrapper">
			<div class="form-wrapper shadow">
				<div class="main-title">车牌号：鲁C SH001</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
					<el-form-item label="检查单" prop="checklist">
						<el-upload v-model="ruleForm.checklist" action="https://jsonplaceholder.typicode.com/posts/"
							list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
							:on-change="onChange1">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
					<el-form-item label="车辆情况" prop="fleet">
						<el-upload v-model="ruleForm.fleet" action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
							:on-preview="handlePictureCardPreview" :on-remove="handleRemove"
							:on-change="onChange2">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
					<el-form-item label="里程表" prop="odometer">
						<el-upload v-model="ruleForm.odometer" action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
							:on-preview="handlePictureCardPreview" :on-remove="handleRemove"
							:on-change="onChange3">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
					<el-form-item label="行驶里程" prop="km">
						<el-col :span="8">
							<el-input v-model="ruleForm.km"></el-input>
						</el-col>
						<el-col class="line" style="margin-left: 10px;" :span="2">KM</el-col>
					</el-form-item>
					<el-form-item label="是否合格" prop="qualified">
						<el-radio-group v-model="ruleForm.qualified">
							<el-radio label="1">合格</el-radio>
							<el-radio label="2">不合格</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="待更换部件" prop="change">
						<el-radio-group v-model="ruleForm.change">
							<el-radio label="1">更换</el-radio>
							<el-radio label="2">不更换</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="更换时间" required>
						<el-col :span="8">
							<el-form-item prop="date">
								<el-date-picker type="date" placeholder="请选择更换日期" v-model="ruleForm.date"
									style="width: 100%;"></el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="更换备注" prop="desc">
						<el-input type="textarea" v-model="ruleForm.desc"></el-input>
					</el-form-item>
					<el-form-item class="form-btn">
						<el-button type="primary" @click="prev()">上一步</el-button>
						<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				dialogImageUrl: '',
				dialogVisible: false,
				ruleForm: {
					km: '',
					checklist: '',
					odometer:'',
					fleet:'',
					date: '',
					qualified: '1',
					change: '1',
					desc: ''
				},
				rules: {
					checklist: [{
						required: true,
						message: "请上传检查单",
						trigger: "change"
					}],
					fleet: [{
						required: true,
						message: "请上传车辆情况",
						trigger: "change"
					}],
					odometer: [{
						required: true,
						message: "请上传里程表",
						trigger: "change"
					}],
					km: [{
						required: true,
						message: '请填写行驶里程',
						trigger: 'blur'
					}],
					date: [{
						type: 'date',
						required: true,
						message: '请选择更换日期',
						trigger: 'change'
					}],
					qualified: [{
						required: true,
						message: '请选择是否合格',
						trigger: 'change'
					}],
					change: [{
						required: true,
						message: '请选择是否更换部件',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请填写更换备注',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			prev() {
				this.$router.go(-1);
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//变化设置图片
			onChange1(file, fileList) {
				this.ruleForm.checklist = fileList;
			},
			onChange2(file, fileList) {
				this.ruleForm.fleet = fileList;
			},
			onChange3(file, fileList) {
				this.ruleForm.odometer = fileList;
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			checkClick(n) {
				if (n == 1) {
					this.$router.push("/firstForm")
				} else {
					this.$router.push("/generalForm")
				}
			}
		},
		created() {}
	}
</script>
<style lang="scss" scoped>

</style>
